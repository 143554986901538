@tailwind base;
@tailwind components;
@tailwind utilities;

/**/

/* 나눔고딕 */
@font-face {
  font-family: "Nanum Gothic";
  font-style: normal;
  font-weight: 700;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.eot);
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.woff2)
      format("woff2"),
    url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.woff)
      format("woff"),
    url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.ttf)
      format("truetype");
}

/* 나눔명조 */
@import url("//fonts.googleapis.com/earlyaccess/nanummyeongjo.css");

/* 나눔바른고딕 */
@font-face {
  font-family: "NanumBarunGothic";
  font-style: normal;
  font-weight: 400;
  src: url("//fastly.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot");
  src: url("//fastly.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot?#iefix")
      format("embedded-opentype"),
    url("//fastly.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.woff")
      format("woff"),
    url("//fastly.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.ttf")
      format("truetype");
}

.nanumbarungothic * {
  font-family: "NanumBarunGothic", sans-serif;
}

/* 나눔스퀘어 */
@import url("https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css");

/* 마루부리 */
@font-face {
  font-family: "MaruBuri";
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.eot);
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.woff2)
      format("woff2"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.woff)
      format("woff"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/MaruBuri/MaruBuri-Regular.ttf)
      format("truetype");
}

.maruburi * {
  font-family: "MaruBuri", sans-serif;
}

/* 다시시작해 */
@font-face {
  font-family: "Restart";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/naverfont_09@1.0/Restart.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

/* 바른히피 */
@font-face {
  font-family: "Bareun_hipi";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/naverfont_01@1.0/Bareun_hipi.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

/* 우리딸 손글씨 */
@font-face {
  font-family: "Daughter_handwriting";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/naverfont_03@1.0/Daughter_handwriting.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

/**/

@import url("//fonts.googleapis.com/earlyaccess/nanummyeongjo.css");

@font-face {
  font-family: "Helvetica";
  font-weight: normal;
  src: url("./fonts/helvetica-light-587ebe5a59211.ttf") format("truetype");
}

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");

.helvetica {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.nanummyeongjo {
  font-family: "Nanum Myeongjo", serif;
}

.nanummyeongjo > * {
  font-family: "Nanum Myeongjo", serif;
  font-style: italic;
}

:root {
  min-height: 100vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nanum Gothic", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

.logo {
  background: url("/public/logo.svg") no-repeat;
}
